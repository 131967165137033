import i18n from '@/i18n';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { WeekDays } from '@/components/atoms/AtomCalendar/AtomCalendarBig.vue';
import WeekDaysEnum from '@/enums/date/WeekDaysEnum';

export const getCalendarViewDays =
  (startingDate: moment.Moment, isoWeek = true): moment.Moment[][] => {
    // we need to handle starting the week on monday (isoWeek) or sunday
    const weekDayFn: string = isoWeek ? 'isoWeekday' : 'day';
    const firstWeekday: number = isoWeek ? 1 : 0;
    const lastWeekday: number = isoWeek ? 7 : 6;

    // ending condition is the last day of the last week of the month
    const endingDate: moment.Moment = moment(startingDate).endOf('month')[weekDayFn](lastWeekday);

    // starting date is the first day (monday or sunday) of the week of the first day of the month
    const day: moment.Moment = moment(startingDate).startOf('month')[weekDayFn](firstWeekday);
    const calendarView: moment.Moment[][] = [];
    let weekDays: moment.Moment[] = [];

    for (day; day.isSameOrBefore(endingDate, 'day'); day.add(1, 'day')) {
      // clear our days of week list when a new week is started
      if (day[weekDayFn]() === firstWeekday) weekDays = [];

      weekDays.push(moment(day));

      // add the full week to the list of weeks
      if (day[weekDayFn]() === lastWeekday) calendarView.push(weekDays);
    }
    return calendarView;
  };

export const isValidDate = (
  value: moment.Moment,
  granularity: moment.unitOfTime.StartOf,
  minimum?: moment.Moment,
  maximum?: moment.Moment,
): boolean => {
  let valid = true;
  if (minimum) {
    valid = valid && value.isSameOrAfter(minimum, granularity);
  }
  if (maximum) {
    valid = valid && value.isSameOrBefore(maximum, granularity);
  }
  return valid;
};

export const useIsoFormat = (language: string): boolean => language.toLocaleLowerCase() !== 'en-us';

export const useAmPm = (locale: string): boolean => (locale === 'en-US' || locale === 'en-GB');

export const monthLiterals = () => [
  i18n.t('rideIntent.dateSelection.months.jan').toString(),
  i18n.t('rideIntent.dateSelection.months.feb').toString(),
  i18n.t('rideIntent.dateSelection.months.mar').toString(),
  i18n.t('rideIntent.dateSelection.months.apr').toString(),
  i18n.t('rideIntent.dateSelection.months.may').toString(),
  i18n.t('rideIntent.dateSelection.months.jun').toString(),
  i18n.t('rideIntent.dateSelection.months.jul').toString(),
  i18n.t('rideIntent.dateSelection.months.aug').toString(),
  i18n.t('rideIntent.dateSelection.months.sep').toString(),
  i18n.t('rideIntent.dateSelection.months.oct').toString(),
  i18n.t('rideIntent.dateSelection.months.nov').toString(),
  i18n.t('rideIntent.dateSelection.months.dec').toString(),
];

export const dayAbbreviations = (): WeekDays[] => [
  {
    dayName: i18n.t('rideIntent.dateSelection.dayAbbreviations.mon').toString(),
    dayCode: WeekDaysEnum.MONDAY,
    isSelected: false,
  },
  {
    dayName: i18n.t('rideIntent.dateSelection.dayAbbreviations.tue').toString(),
    dayCode: WeekDaysEnum.TUESDAY,
    isSelected: false,
  },
  {
    dayName: i18n.t('rideIntent.dateSelection.dayAbbreviations.wed').toString(),
    dayCode: WeekDaysEnum.WEDNESDAY,
    isSelected: false,
  },
  {
    dayName: i18n.t('rideIntent.dateSelection.dayAbbreviations.thu').toString(),
    dayCode: WeekDaysEnum.THURSDAY,
    isSelected: false,
  },
  {
    dayName: i18n.t('rideIntent.dateSelection.dayAbbreviations.fri').toString(),
    dayCode: WeekDaysEnum.FRIDAY,
    isSelected: false,
  },
  {
    dayName: i18n.t('rideIntent.dateSelection.dayAbbreviations.sat').toString(),
    dayCode: WeekDaysEnum.SATURDAY,
    isSelected: false,
  },
  {
    dayName: i18n.t('rideIntent.dateSelection.dayAbbreviations.sun').toString(),
    dayCode: WeekDaysEnum.SUNDAY,
    isSelected: false,
  },
];
