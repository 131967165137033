
import Vue, { PropType } from 'vue';
import moment from 'moment';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomCheckbox from '@/components/atoms/AtomCheckbox.vue';

import WeekDaysEnum from '@/enums/date/WeekDaysEnum';
// eslint-disable-next-line import/no-cycle
import { getCalendarViewDays, isValidDate } from '@/common/dateCalculations';

export const CHANGE_EVENT_NAME = 'date-change';
export const CHANGE_RANGE_EVENT_NAME = 'date-range-change';

export interface WeekDays {
  dayName: string;
  dayCode: WeekDaysEnum;
  isSelected?: boolean;
}

export default Vue.extend({
  name: 'AtomCalendarBig',
  components: { AtomSvgIcon, AtomCheckbox },
  props: {
    // used for the headline besides the year and to not bind the component itself to translations
    monthNames: {
      type: Array as PropType<string[]>,
      required: true,
    },
    // same as with month literals, expected order is monday -> sunday
    weekDays: {
      type: Array as PropType<WeekDays[]>,
      required: true,
    },
    // date to be selected at the start
    initialDate: {
      type: moment as PropType<moment.Moment>,
      required: false,
    },
    rangeDate: {
      type: moment as PropType<null | moment.Moment>,
      required: false,
      default: null,
    },
    // isoWeek means the week starts with monday
    isoWeek: {
      type: Boolean,
      required: false,
    },
    recurrence: {
      type: Boolean,
      default: false,
    },
    rangeCalendar: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableRecurrenceToggle: {
      type: Boolean,
      default: false,
    },
    disableRecurrenceDaysToggle: {
      type: Boolean,
      default: false,
    },
    // the latest valid date (including the date stated), used for styling and being selectable
    minDate: {
      type: moment,
      required: false,
    },
    maxDate: {
      type: moment,
      required: false,
    },
    // UI controls
    monthNavigation: {
      type: Boolean,
      default: true,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    internalMonth: null as moment.Moment | null,
  }),
  computed: {
    monthAndYear(): string {
      return `${this.monthNames[this.selectedMonth.month()]} ${this.selectedMonth.year()}`;
    },
    calendarView(): moment.Moment[][] {
      return getCalendarViewDays(moment(this.selectedMonth), this.isoWeek);
    },
    sortedDayLiterals(): WeekDays[] {
      if (this.isoWeek) {
        return this.weekDays;
      }
      return [this.weekDays[6]].concat(this.weekDays.slice(0, 6));
    },
    todaysDate: {
      get(): moment.Moment {
        return moment().startOf('day');
      },
    },
    selectedMonth: {
      get(): moment.Moment {
        return this.internalMonth || moment(this.initialDate);
      },
      set(date: moment.Moment) {
        this.internalMonth = moment(date);
      },
    },
    isRangeEnabled(): boolean {
      return this.rangeCalendar;
    },
    selectedDaysCodes(): WeekDaysEnum[] {
      // console.log(this.weekDays);
      return this.weekDays.reduce((acc, el) => {
        if (el.isSelected) acc.push(el.dayCode);
        return acc;
      }, [] as WeekDaysEnum[]);
    },
    rangeDaysDiff(): number {
      if (this.rangeDate) return this.rangeDate.diff(this.initialDate, 'days');
      return Infinity;
    },
  },
  methods: {
    toggleRecurrence(status: boolean): void {
      this.$emit('toggle-recurrence', status);
    },
    selectPreviousMonth(): void {
      const targetValue = moment(this.selectedMonth).subtract(1, 'months');
      // check if we would navigate before the month of the minimum value
      this.selectedMonth = targetValue;
    },
    selectNextMonth(): void {
      // moments are mutable, so Vue wouldn't catch any changes if we didn't create a new object
      this.selectedMonth = this.selectedMonth.add(1, 'months').clone();
    },
    isDaySelectable(index: number) {
      if (!this.recurrence) return true;
      return this.sortedDayLiterals[index].isSelected;
    },
    isSameDay(day: moment.Moment, day2: moment.Moment): boolean {
      return day.diff(day2, 'day') === 0;
    },
    calculateDateCellClasses(date: moment.Moment, index: number): object {
      return {
        unHighlightedDate: !date.isSame(this.selectedMonth, 'month') || !isValidDate(date, 'day', this.todaysDate),
        inactive: !isValidDate(date, 'day', this.todaysDate) || this.disabled,
        selected: date.isSame(this.initialDate, 'day'),
        rangeDate: (
          this.rangeDate
          && date.isSame(this.rangeDate, 'day')
          && !this.isSameDay(this.rangeDate, this.initialDate)
        ),
        'date-in-range': (
          date.isAfter(this.initialDate, 'day')
          && (this.rangeDate === null || date.isBefore(this.rangeDate, 'day'))
          && this.isDaySelectable(index)
        ),
        'seven-days-range': this.rangeDaysDiff < 6,
        'first-week-day': this.rangeDate && date.diff(this.initialDate, 'days') <= 6,
        'last-week-day': this.rangeDate && this.rangeDate.diff(date, 'days') <= 6,
        current: date.isSame(this.todaysDate, 'day'),
      };
    },
    dateSelectHandler(date: moment.Moment, index: number): void {
      if (this.disabled) return;

      const isDateValid = isValidDate(date, 'day', this.todaysDate, this.maxDate);
      const selectedDate = moment(date);

      if (!this.isRangeEnabled && isDateValid && this.isDaySelectable(index)) {
        this.$emit(CHANGE_EVENT_NAME, moment(date));
      }
      if (
        isDateValid && this.isDaySelectable(index)
        && this.isRangeEnabled
        && (!this.rangeDate || (this.rangeDate && this.rangeDate.isAfter(this.initialDate) && this.rangeDate.diff(this.initialDate, 'days') > 90))
      ) {
        this.$emit(CHANGE_RANGE_EVENT_NAME, moment(date));
      } else if (
        isDateValid && this.isDaySelectable(index)
        && this.isRangeEnabled
        && this.rangeDate
      ) {
        this.$emit(CHANGE_RANGE_EVENT_NAME, null);
        this.$emit(CHANGE_EVENT_NAME, moment(date));
      }
      // console.log(this.initialDate);
      if (!this.initialDate.isSame(this.selectedMonth, 'month')) {
        this.selectedMonth = selectedDate;
      }
    },
  },
  watch: {
    initialDate(newVal: moment.Moment): void {
      this.internalMonth = moment(newVal);
    },
  },
});
