var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"atom-calendar test-atom-calendar gap-16",class:{
    invalid: !_vm.isValid,
    recurrence: _vm.recurrence,
    'range-selection': _vm.rangeCalendar,
    disabled: _vm.disableRecurrenceToggle,
  }},[_c('div',{staticClass:"controls"},[(_vm.monthNavigation)?_c('div',{staticClass:"year-month-selection"},[_c('AtomSvgIcon',{attrs:{"action":_vm.selectPreviousMonth,"className":"rotate arrow","imageSrc":"icons/icon-chevron-right.svg","height":"24"}}),_vm._v(" "+_vm._s(_vm.monthAndYear)+" "),_c('AtomSvgIcon',{attrs:{"action":_vm.selectNextMonth,"className":"arrow","imageSrc":"icons/icon-chevron-right.svg","height":"24"}})],1):_vm._e()]),_c('div',{staticClass:"calendar"},[_c('div',{staticClass:"calendar-row headline"},_vm._l((_vm.sortedDayLiterals),function(weekDay,idx){return _c('div',{key:`${idx}-${weekDay.dayCode}`,staticClass:"d-flex flex-column align-items-center gap-8"},[_c('span',{class:{hidden: !_vm.recurrence}},[_c('AtomCheckbox',{attrs:{"checked":weekDay.isSelected,"disabled":_vm.selectedDaysCodes.length === 1 && _vm.selectedDaysCodes.includes(weekDay.dayCode)
              || _vm.disableRecurrenceDaysToggle,"id":weekDay.dayCode,"content":""},on:{"change":function($event){return _vm.$emit('change-recurrence', $event, weekDay.dayCode)}}})],1),_c('span',{class:{ 'selected-day': _vm.recurrence && weekDay.isSelected }},[_vm._v(_vm._s(weekDay.dayName))])])}),0),_vm._l((_vm.calendarView),function(week,idx){return _c('div',{key:idx,staticClass:"calendar-row"},_vm._l((week),function(day,idx){return _c('div',{key:idx,staticClass:"cell",on:{"click":function($event){return _vm.dateSelectHandler(day, idx)}}},[_c('div',{staticClass:"cell-content",class:_vm.calculateDateCellClasses(day, idx)},[_vm._v(" "+_vm._s(day.date())+" ")])])}),0)})],2),_c('div',{staticClass:"d-flex justify-content-between align-items-center gap-8"},[_c('AtomCheckbox',{attrs:{"content":_vm.$t('rideIntent.extras.repeat'),"id":"toggle-recurrence","checked":_vm.recurrence,"disabled":_vm.disableRecurrenceToggle || _vm.disabled},on:{"change":_vm.toggleRecurrence}}),_vm._t("controls")],2),_vm._t("messages")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }