
import Vue, { PropType } from 'vue';
import moment from 'moment';
import { BTooltip } from 'bootstrap-vue';
import ClickOutside from 'vue-click-outside';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';

import WeekDaysEnum from '@/enums/date/WeekDaysEnum';
// eslint-disable-next-line import/no-cycle
import { getCalendarViewDays, isValidDate } from '@/common/dateCalculations';

export const CHANGE_EVENT_NAME = 'date-change';
export const CHANGE_RANGE_EVENT_NAME = 'date-range-change';

export interface WeekDays {
  dayName: string;
  dayCode: WeekDaysEnum;
  isSelected?: boolean;
}

export default Vue.extend({
  name: 'AtomCalendarContextual',
  components: { AtomButton, AtomSvgIcon, AtomText, BTooltip },
  props: {
    // used for the headline besides the year and to not bind the component itself to translations
    monthNames: {
      type: Array as PropType<string[]>,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    btnTitle: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    emitOnlyRange: {
      type: Boolean,
      default: false,
    },
    emitOnlyInitialDate: {
      type: Boolean,
      default: true,
    },
    // same as with month literals, expected order is monday -> sunday
    weekDays: {
      type: Array as PropType<WeekDays[]>,
      required: true,
    },
    // date to be selected at the start
    initialDate: {
      type: moment as PropType<moment.Moment>,
      required: false,
    },
    rangeDate: {
      type: moment as PropType<null | moment.Moment>,
      required: false,
      default: null,
    },
    // isoWeek means the week starts with monday
    isoWeek: {
      type: Boolean,
      required: false,
    },
    rangeCalendar: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // the latest valid date (including the date stated), used for styling and being selectable
    minDate: {
      type: moment,
      required: false,
    },
    maxDate: {
      type: moment,
      required: false,
    },
    // UI controls
    monthNavigation: {
      type: Boolean,
      default: true,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    ButtonVariant,
    internalMonth: null as moment.Moment | null,
  }),
  computed: {
    monthAndYear(): string {
      return `${this.monthNames[this.selectedMonth.month()]} ${this.selectedMonth.year()}`;
    },
    calendarView(): moment.Moment[][] {
      return getCalendarViewDays(moment(this.selectedMonth), this.isoWeek);
    },
    sortedDayLiterals(): WeekDays[] {
      if (this.isoWeek) {
        return this.weekDays;
      }
      return [this.weekDays[6]].concat(this.weekDays.slice(0, 6));
    },
    todaysDate: {
      get(): moment.Moment {
        return moment().startOf('day');
      },
    },
    selectedMonth: {
      get(): moment.Moment {
        if (this.emitOnlyRange) {
          if (this.internalMonth) return this.internalMonth.clone();
          if (this.rangeDate === null) return this.initialDate.clone();

          return this.rangeDate.clone();
        }

        return (this.internalMonth?.clone() || this.initialDate.clone());
      },
      set(date: moment.Moment) {
        this.internalMonth = moment(date);
      },
    },
    isRangeEnabled(): boolean {
      return this.rangeCalendar;
    },
    selectedDaysCodes(): WeekDaysEnum[] {
      return this.weekDays.reduce((acc, el) => {
        if (el.isSelected) acc.push(el.dayCode);
        return acc;
      }, [] as WeekDaysEnum[]);
    },
    rangeDaysDiff(): number {
      return this.rangeDate ? this.rangeDate.diff(this.initialDate, 'days') : 0;
    },
  },
  methods: {
    toolTipText(day: moment.Moment): string {
      if (day.isSame(this.initialDate, 'day')) return this.$t('dictionary.startDay').toString();
      if (day.isSame(this.rangeDate, 'day')) return this.$t('dictionary.endDay').toString();

      return '';
    },
    isDaySelectable(index: number) {
      return this.sortedDayLiterals[index].isSelected;
    },
    selectPreviousMonth(): void {
      const date = this.selectedMonth.clone();
      const targetValue = date.subtract(1, 'months');
      // check if we would navigate before the month of the minimum value
      this.selectedMonth = targetValue;
    },
    selectNextMonth(): void {
      // moments are mutable, so Vue wouldn't catch any changes if we didn't create a new object
      const date = this.selectedMonth.clone();
      this.selectedMonth = date.add(1, 'months');
    },
    isSameDay(day: moment.Moment, day2: moment.Moment): boolean {
      return day.diff(day2, 'days') === 0;
    },
    calculateDateCellClasses(date: moment.Moment, idx: number): object {
      return {
        unHighlightedDate: (
          !date.isSame(this.selectedMonth, 'month')
          || !isValidDate(date, 'day', this.todaysDate)
          || !this.isDaySelectable(idx)
        ),
        inactive: !isValidDate(date, 'day', this.todaysDate) || this.disabled || !this.isDaySelectable(idx),
        selected: date.isSame(this.initialDate, 'day'),
        rangeDate: (
          this.rangeDate
          && date.isSame(this.rangeDate, 'day')
          && !this.isSameDay(this.rangeDate, this.initialDate)
        ),
        'date-in-range': (
          date.isAfter(this.initialDate, 'day')
          && date.isBefore(this.rangeDate, 'day')
        ),
        'seven-days-range': this.rangeDaysDiff < 6,
        current: date.isSame(this.todaysDate, 'day'),
      };
    },
    resetRangeDate(): void {
      this.$emit(CHANGE_RANGE_EVENT_NAME, null);
    },
    dateSelectHandler(date: moment.Moment, idx: number): void {
      if (this.disabled || !this.isDaySelectable(idx)) return;

      const isDateValid = isValidDate(date, 'day', this.minDate, this.maxDate);
      if (!isDateValid) return;

      if (this.emitOnlyRange) {
        this.$emit(CHANGE_RANGE_EVENT_NAME, moment(date));
        return;
      }
      if (this.emitOnlyInitialDate) {
        this.$emit(CHANGE_EVENT_NAME, moment(date));
      }
    },
    hideCalendar() {
      if (!this.isOpen) this.$emit('onToggle', this.isOpen);
    },
  },
  mounted() {
    // prevent click outside event with popupItem.
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.popupItem = this.$el;
  },
  watch: {
    initialDate(newVal: moment.Moment): void {
      if (this.emitOnlyInitialDate) this.internalMonth = moment(newVal);
    },
    rangeDate(newVal: moment.Moment): void {
      if (!newVal) {
        this.internalMonth = moment(this.initialDate);
        return;
      }
      if (this.emitOnlyRange) this.internalMonth = moment(newVal);
    },
  },
  directives: {
    ClickOutside,
  },
});
