
import Vue, { PropType } from 'vue';
import moment from 'moment';
import MoleculeDateTimePicker, {
  CHANGE_EVENT_NAME, CHANGE_EVENT_NAME_TWO,
} from '@/components/molecules/date/MoleculeDateTimePicker.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import {
  dayAbbreviations, monthLiterals, useAmPm, useIsoFormat,
} from '@/common/dateCalculations';
import { Trip } from '@/store/modules/ride-intent/state';
import { WeekDays } from '@/components/atoms/AtomCalendar/AtomCalendarBig.vue';

export default Vue.extend({
  name: 'OrganismDateSelection',
  components: {
    MoleculeDateTimePicker,
    AtomText,
  },
  props: {
    trip: {
      type: Object as PropType<Trip>,
      required: true,
    },
    setEarliestDeparture: {
      type: Function,
      required: true,
    },
    setLatestArrival: {
      type: Function,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    earliestDepartureHeadline: {
      type: String,
      required: true,
    },
    latestArrivalHeadline: {
      type: String,
      required: true,
    },
    isCalendarValid: {
      type: Boolean,
      default: true,
    },
    departureQuickSlots: {
      type: Array,
    },
    arrivalQuickSlots: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableRecurrenceToggle: {
      type: Boolean,
      default: false,
    },
    disableRecurrenceDaysToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      today: moment(),
      changeEvent: CHANGE_EVENT_NAME,
      changeArrival: CHANGE_EVENT_NAME_TWO,
      isLatestArrivalChanged: false,
    };
  },
  computed: {
    monthLiterals(): string[] {
      return monthLiterals();
    },
    dayAbbreviations(): WeekDays[] {
      return dayAbbreviations();
    },
    useAmPm(): boolean {
      return useAmPm(this.$i18n.locale);
    },
    useIsoFormat(): boolean {
      return useIsoFormat(navigator.language);
    },
  },
  methods: {
    setDeparture(value: moment.Moment) {
      this.setEarliestDeparture({ value, keepLatestArrivalInSync: !this.isLatestArrivalChanged });
    },
    setArrival(value: moment.Moment) {
      this.setLatestArrival(value);
      this.isLatestArrivalChanged = true;
    },
  },
  mounted() {
    const { earliestDeparture, latestArrival } = this.trip;
    this.isLatestArrivalChanged = latestArrival.diff(earliestDeparture, 'hours') > 2;
  },
});
