
import Vue, { PropType } from 'vue';
import moment from 'moment';
import AtomCheckbox from '@/components/atoms/AtomCheckbox.vue';
import AtomNotification, { NotificationVariant } from '@/components/atoms/AtomNotification.vue';
import AtomChip from '@/components/atoms/AtomChip.vue';
import AtomCalendarBig, {
  CHANGE_EVENT_NAME as DATE_CHANGE_EVENT_NAME,
  WeekDays,
  CHANGE_RANGE_EVENT_NAME,
} from '@/components/atoms/AtomCalendar/AtomCalendarBig.vue';
import AtomCalendarContextual from '@/components/atoms/AtomCalendar/AtomCalendarContextual.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomTimePicker, {
  CHANGE_EVENT_NAME as TIME_CHANGE_EVENT_NAME,
} from '@/components/atoms/AtomTimePicker.vue';

import { TripFrequency } from '@/store/modules/ride-intent/state';
import { mapActions, mapGetters } from 'vuex';
import WeekDaysEnum from '@/enums/date/WeekDaysEnum';

export const CHANGE_EVENT_NAME = 'dateTimeChangeDeparture';
export const CHANGE_EVENT_NAME_TWO = 'dateTimeChangeArrival';

export default Vue.extend({
  name: 'MoleculeDateTimePicker',
  components: {
    AtomCalendarBig,
    AtomCalendarContextual,
    AtomCheckbox,
    AtomChip,
    AtomText,
    AtomTimePicker,
    AtomNotification,
  },
  props: {
    monthLiterals: {
      type: Array as PropType<string[]>,
      required: true,
    },
    dayLiterals: {
      type: Array as PropType<WeekDays[]>,
      required: true,
    },
    // ISO Format will result in 0-23 hours and week starting mondays
    useIsoFormat: {
      type: Boolean,
      required: false,
    },
    useAmPm: {
      type: Boolean,
      required: false,
    },
    initialValue: {
      type: moment,
      required: false,
    },
    initialValueArr: {
      type: moment,
      required: false,
    },
    minimumDate: {
      type: moment,
      required: false,
    },
    maximumDate: {
      type: moment,
      required: false,
    },
    isCalendarValid: {
      type: Boolean,
      default: true,
    },
    departureQuickSlots: {
      type: Array,
    },
    arrivalQuickSlots: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableRecurrenceToggle: {
      type: Boolean,
      default: false,
    },
    disableRecurrenceDaysToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      departure: null as null | moment.Moment,
      arrival: null as null | moment.Moment,
      isArrivalOnNextDay: null as null|boolean,

      isStartOpen: true,
      isEndOpen: true,

      dateChangeEventName: DATE_CHANGE_EVENT_NAME,
      timeChangeEventName: TIME_CHANGE_EVENT_NAME,
      timeRangeChangeEventName: CHANGE_RANGE_EVENT_NAME,
      NotificationVariant,
      TripFrequency,
    };
  },
  computed: {
    ...mapGetters('rideIntent', ['getOtherDetails', 'isEditMode']),
    departureDate: {
      get(): moment.Moment {
        return this.departure || this.initialValue;
      },
      set(date: moment.Moment) {
        this.departure = date;
      },
    },
    arrivalDate: {
      get(): moment.Moment {
        return this.arrival || this.initialValueArr;
      },
      set(date: moment.Moment) {
        this.arrival = date;
      },
    },
    getDayNames(): WeekDays[] {
      const { frequency, on } = this.getOtherDetails.repeat as { frequency: TripFrequency; on: WeekDaysEnum[]};
      if (frequency === TripFrequency.RECURRENT) {
        return this.dayLiterals.map((day) => {
          if (on.includes(day.dayCode)) return { ...day, isSelected: true };
          return { ...day, isSelected: false };
        });
      }

      return this.dayLiterals;
    },
    isRecurrenceMode(): boolean {
      return this.getOtherDetails.repeat.frequency === TripFrequency.RECURRENT;
    },
  },
  methods: {
    ...mapActions('rideIntent', [
      'selectRole',
      'setBusinessTrip',
      'setLadiesOnly',
      'setOffersPayment',
      'setClaimsPayment',
      'setFrequencyType',
      'setFrequency',
      'removeFrequency',
      'clearFrequency',
      'setBulkFrequency',
      'setFrequencyEnd',
      'setOrganizationId',
      'setDetour',
      'setDefaultDetour',
    ]),
    applyTime(date: moment.Moment, time: moment.Moment): moment.Moment {
      return date.clone().hours(time.hours()).minutes(time.minutes()).seconds(0);
    },
    changeDepartureDate(date: moment.Moment, openEndDateCalendar = false): void {
      this.departureDate = this.applyTime(date, this.departureDate);
      this.$emit(CHANGE_EVENT_NAME, moment(this.departureDate));
      this.changeArrivalDate(this.departureDate);

      const rangeDate = this.getOtherDetails.repeat.until;

      if (date && rangeDate?.isSameOrBefore(date, 'day')) {
        this.setFrequencyEnd(null);
      }

      if (openEndDateCalendar) {
        this.setIsEndOpen(false);
        this.setIsStartOpen(true);
      }
    },
    handleRangeDateChange(date: null|moment.Moment) {
      if (this.isRecurrenceMode) {
        if (date && this.departureDate.isSameOrAfter(date)) {
          this.changeDepartureDate(date);
          this.setFrequencyEnd(null);
        } else {
          this.setFrequencyEnd(date);
        }
      }
    },
    changeArrivalDate(date: moment.Moment): void {
      const { isArrivalOnNextDay } = this;
      this.arrivalDate = this
        .applyTime(date, this.arrivalDate)
        .add(isArrivalOnNextDay ? 1 : 0, 'days');
      this.$emit(CHANGE_EVENT_NAME_TWO, moment(this.arrivalDate));
    },
    setDepartureTime(time: moment.Moment) {
      this.departureDate = this.applyTime(this.departureDate, time);
      this.$emit(CHANGE_EVENT_NAME, moment(this.departureDate));
      this.isArrivalOnNextDay = this.departureDate.isBefore(this.arrivalDate, 'day');
    },
    setArrivalTime(time: moment.Moment) {
      this.arrivalDate = this.applyTime(this.arrivalDate, time);
      this.$emit(CHANGE_EVENT_NAME_TWO, moment(this.arrivalDate));
    },
    setFrequencyOption(status: boolean): void {
      if (!status) {
        this.setFrequencyType(TripFrequency.NEVER);
        this.clearFrequency();
      }
      if (status) {
        this.setFrequencyType(TripFrequency.RECURRENT);
        this.preselectFrequency(TripFrequency.RECURRENT);
      }
    },
    handleFrequency(active: boolean, day: string): void {
      const { setFrequency, removeFrequency } = this;
      if (active) setFrequency(day);
      else removeFrequency(day);
    },
    preselectFrequency(frequencyType: TripFrequency) {
      const outBoundDate = this.initialValue.clone() as moment.Moment;
      this.setFrequencyType(frequencyType);
      this.setFrequency(outBoundDate.format('dd').toUpperCase());
      this.setFrequencyEnd(null);
    },
    setIsStartOpen(status: boolean) {
      this.isStartOpen = status;
    },
    setIsEndOpen(status: boolean) {
      this.isEndOpen = status;
    },
  },
  mounted() {
    this.isArrivalOnNextDay = this.departureDate.isBefore(this.arrivalDate, 'day');
  },
  watch: {
    isArrivalOnNextDay(status: boolean, oldStatus: null|boolean): void {
      if (oldStatus === null) return;
      this.changeArrivalDate(this.departureDate);
    },
  },
});
