enum WeekDaysEnum {
  MONDAY = 'MO',
  TUESDAY = 'TU',
  WEDNESDAY = 'WE',
  THURSDAY = 'TH',
  FRIDAY = 'FR',
  SATURDAY = 'SA',
  SUNDAY = 'SU',
}

export const mapWeekDaysEnumToTranslations = {
  [WeekDaysEnum.MONDAY]: 'calendar.weekday.mon',
  [WeekDaysEnum.TUESDAY]: 'calendar.weekday.tue',
  [WeekDaysEnum.WEDNESDAY]: 'calendar.weekday.wed',
  [WeekDaysEnum.THURSDAY]: 'calendar.weekday.thu',
  [WeekDaysEnum.FRIDAY]: 'calendar.weekday.fri',
  [WeekDaysEnum.SATURDAY]: 'calendar.weekday.sat',
  [WeekDaysEnum.SUNDAY]: 'calendar.weekday.sun',
};

export const mapWeekDaysOrder = {
  [WeekDaysEnum.MONDAY]: 0,
  [WeekDaysEnum.TUESDAY]: 1,
  [WeekDaysEnum.WEDNESDAY]: 2,
  [WeekDaysEnum.THURSDAY]: 3,
  [WeekDaysEnum.FRIDAY]: 4,
  [WeekDaysEnum.SATURDAY]: 5,
  [WeekDaysEnum.SUNDAY]: 6,
};

export default WeekDaysEnum;
