
import Vue from 'vue';
import moment, { unitOfTime } from 'moment';

import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export const CHANGE_EVENT_NAME = 'timechange';

export default Vue.extend({
  name: 'AtomTimePicker',
  components: {
    AtomSvgIcon,
  },
  props: {
    useAmPm: {
      type: Boolean,
      required: false,
    },
    initialTime: {
      type: moment,
      required: false,
    },
  },
  data() {
    return {
      internalTime: null as moment.Moment | null,
      hourEditing: false,
      minuteEditing: false,
    };
  },
  computed: {
    selectedTime: {
      get(): moment.Moment {
        return this.internalTime || this.initialTime;
      },
      set(time: moment.Moment) {
        this.internalTime = moment(time);
        this.$emit(CHANGE_EVENT_NAME, time);
      },
    },
    hour: {
      get(): string {
        return this.useAmPm ? this.selectedTime.format('hh') : this.selectedTime.format('HH');
      },
      set(rawValue: string): void {
        // this will "remember" the last valid input
        const value = Number(rawValue);
        const maxHour = this.useAmPm ? 12 : 23;
        if (value >= 0 && value <= maxHour) {
          this.selectedTime = moment(this.selectedTime).hours(Number(value));
        }
      },
    },
    minute: {
      get(): string {
        return this.selectedTime.format('mm');
      },
      set(rawValue: string): void {
        const value = Number(rawValue);
        if (value >= 0 && value < 60) {
          this.selectedTime = moment(this.selectedTime).minutes(Number(value));
        }
      },
    },
    amPm(): string {
      return this.selectedTime.format('A');
    },
  },
  methods: {
    increaseMinutes(): void {
      const currentMinutes = this.selectedTime.minutes();
      // we want to round up to the next 15 minute interval
      const amount = 15 - (currentMinutes % 15);
      this.increase(amount, 'minutes');
    },
    decreaseMinutes(): void {
      const currentMinutes = this.selectedTime.minutes();
      // we want to round down to the previous 15 minute interval
      const amount = currentMinutes % 15 || 15;
      this.decrease(amount, 'minutes');
    },
    increase(amount: number, unit: unitOfTime.DurationConstructor): void {
      this.selectedTime = moment(this.selectedTime).add(amount, unit);
    },
    decrease(amount: number, unit: unitOfTime.DurationConstructor): void {
      this.selectedTime = moment(this.selectedTime).subtract(amount, unit);
    },
    toggleEditing(field: string): void {
      this[field] = true;
      // necessary since we render the input now, and before vue can't access the ref
      this.$nextTick(() => {
        const input = this.$refs[field] as HTMLInputElement;
        input.select();
      });
    },
  },
  watch: {
    initialTime(newVal: moment.Moment): void {
      this.internalTime = moment(newVal);
    },
  },
});
