var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"calendar-contextual-wrapper"},[_c('AtomButton',{attrs:{"variant":_vm.ButtonVariant.LINK,"content":_vm.btnTitle,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('onToggle', _vm.isOpen)}}}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideCalendar),expression:"hideCalendar"}],staticClass:"atom-calendar test-atom-calendar gap-16",class:{
      invalid: !_vm.isValid,
      'range-selection-present': _vm.rangeCalendar && !!_vm.rangeDate,
      disabled: _vm.disabled,
      hidden: _vm.isOpen,
      emitOnlyRange: _vm.emitOnlyRange,
      emitOnlyInitialDate: _vm.emitOnlyInitialDate,
    }},[_c('AtomText',{attrs:{"tag":"h4","noMargin":"","textCenter":""}},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"controls"},[(_vm.monthNavigation)?_c('div',{staticClass:"year-month-selection"},[_c('AtomSvgIcon',{attrs:{"action":_vm.selectPreviousMonth,"className":"rotate arrow","imageSrc":"icons/icon-chevron-right.svg","height":"24"}}),_vm._v(" "+_vm._s(_vm.monthAndYear)+" "),_c('AtomSvgIcon',{attrs:{"action":_vm.selectNextMonth,"className":"arrow","imageSrc":"icons/icon-chevron-right.svg","height":"24"}})],1):_vm._e(),(_vm.emitOnlyRange)?_c('AtomButton',{attrs:{"variant":_vm.ButtonVariant.LINK,"content":_vm.$t('dictionary.noEnd')},on:{"click":_vm.resetRangeDate}}):_vm._e()],1),_c('div',{staticClass:"calendar"},[_c('div',{staticClass:"calendar-row headline"},_vm._l((_vm.sortedDayLiterals),function(weekDay,idx){return _c('div',{key:`${idx}-${weekDay.dayCode}`},[_c('span',[_vm._v(_vm._s(weekDay.dayName))])])}),0),_vm._l((_vm.calendarView),function(week,idx){return _c('div',{key:idx,staticClass:"calendar-row"},_vm._l((week),function(day,idx){return _c('div',{key:idx,staticClass:"cell",on:{"click":function($event){$event.stopPropagation();return _vm.dateSelectHandler(day, idx)}}},[_c('div',{staticClass:"cell-content",class:_vm.calculateDateCellClasses(day, idx),attrs:{"id":`tooltip-${day.unix()}-${_vm.emitOnlyRange ? idx : ''}`}},[_vm._v(" "+_vm._s(day.date())+" "),(_vm.toolTipText(day))?_c('BTooltip',{attrs:{"target":`tooltip-${day.unix()}-${_vm.emitOnlyRange ? idx : ''}`,"triggers":"hover","placement":"top"}},[_vm._v(" "+_vm._s(_vm.toolTipText(day))+" ")]):_vm._e()],1)])}),0)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }